import React, { useState } from "react";
import "../../../theme/css/Portfolio.css";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Trucks from "./trucks";

export default function Elements() {
  return (
    <>
      <div className="otherPageBanner portfolioBanner">
        <div className="text-center title">
          <h2>TRUCKS LIST</h2>
          <h6>Below is a list of what is in store...</h6>
        </div>
      </div>
      <div className="container">
        <div className="spacing blogPage">
          <Row>
            {Trucks.map((trucksdata) => (
              <Col xl={3} lg={4} md={6} sm={6} key={trucksdata.id}>
                <div className="blogCardMainClass blog">
                  <div className="mainBlog">
                    <div className="recent">
                      {/* <Link to="/blogpage"> */}
                        <img
                          className="img-fluid mx-auto"
                          variant="top"
                          src={trucksdata.image}
                          alt="blog"
                        />
                        <div className="blog-post-hover">
                          <div className="blog-icon">
                            <div className="instaicon">
                              {/* <i className="fa fa-search"></i> */}
                            </div>
                          </div>
                        </div>
                      {/* </Link> */}
                    </div>
                    <div className="mainBlogBody">
                      <h2>{trucksdata.title}</h2>
                      <hr />
                      <ReadMoreText text={trucksdata.contain} />
                      <Link to={`/gallery/${trucksdata.title}`}>
                      <button className="ourSurvice">View </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

// ReadMoreText Component
function ReadMoreText({ text }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // Display first 100 characters initially
  const truncatedText = text.length > 100 ? `${text.slice(0, 100)}...` : text;

  return (
    <p className="paragraph">
      {isReadMore ? truncatedText : text}
      <span
        onClick={toggleReadMore}
        className="read-more-link"
        style={{ color: "blue", cursor: "pointer" }}
      >
        {isReadMore ? " Read More" : " Read Less"}
      </span>
    </p>
  );
}
