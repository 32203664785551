import React from "react";
import { useParams } from "react-router-dom";

const TruckGallery = () => {
  const { truckName } = useParams();

  // Define all possible image paths
  const maxImages = 10; // Adjust this based on the maximum possible images
  const truckImages = [];

  for (let i = 1; i <= maxImages; i++) {
    try {
      const imagePath = require(`../../../assets/Images/Gallery/${truckName}/image${i}.jpg`);
      truckImages.push(imagePath);
    } catch (err) {
      // Skip if the file doesn't exist
      console.warn(`Image ${i} for ${truckName} not found.`);
    }
  }

  return (
    <>
      <div className="otherPageBanner portfolioBanner">
        <div className="text-center title">
          <h2>Gallery</h2>
          <h6>{truckName}</h6>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {truckImages.map((image, index) => (
            <div className="col-md-4" key={index}>
              <img
                src={image}
                alt={`${truckName} ${index + 1}`}
                className="img-fluid"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TruckGallery;
