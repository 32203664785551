import blogcontain1 from "../../../assets/Images/Blog/blogcontain1.jpg";
import blogcontain2 from "../../../assets/Images/Blog/blogcontain2.jpg";
import blogcontain3 from "../../../assets/Images/Blog/blogcontain3.jpg";
import blogcontain4 from "../../../assets/Images/Blog/blogcontain4.jpg";
import blogcontain5 from "../../../assets/Images/Blog/blogcontain5.jpg";
import blogcontain6 from "../../../assets/Images/Blog/blogcontain6.jpg";
import blogcontain7 from "../../../assets/Images/Blog/blogcontain7.jpg";

export const BlogData = [
  // {
  //   id: 1,
  //   image: blogcontain1,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Art therapy",
  //   contain:
  //     "Art therapy in theatre is a powerful way to explore and express emotions, offering a creative outlet for mental health. Through movement, storytelling, and visual arts, it provides a safe space to heal, connect, and find inner peace. In the theatre, art becomes more than just performance—it becomes a journey of self-discovery and emotional well-being.",
  // },
  {
    id: 2,
    image: blogcontain2,
    date: "10 March 2024",
    comments: "",
    like: "",
    title: "Service",
    contain:
      "Truckmart prides itself on being inclusive of all demographics, ensuring that every customer feels welcomed and valued. By offering a diverse range of services, including garage repairs, truck sales, and spare part sales, Truckmart caters to the unique needs of individuals from various backgrounds and experiences.",
  },
  // {
  //   id: 3,
  //   image: blogcontain3,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Text Post",
  //   contain:
  //     "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ",
  // },
  // {
  //   id: 4,
  //   image: blogcontain4,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Gallery Post",
  //   contain:
  //     "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ",
  // },
  // {
  //   id: 5,
  //   image: blogcontain5,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Secure active living depend son repair day ladies now.",
  //   contain:
  //     "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ",
  // },
  // {
  //   id: 6,
  //   image: blogcontain6,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Secure active living depend son repair day ladies now.",
  //   contain:
  //     "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ",
  // },
  // {
  //   id: 7,
  //   image: blogcontain7,
  //   date: "14 March 2022",
  //   comments: "251 comments",
  //   like: "596 likes",
  //   title: "Secure active living depend son repair day ladies now.",
  //   contain:
  //     "Lorem ipsum dolor sit amet, consectetur adipisi cing elit. Molestias eius illum libero dolor nobis deleniti, sint assumenda. Pariatur iste veritatis excepturi, ipsa optio nobis. Molestias eius illum libero dolor nobis deleniti ",
  // },
];
export default BlogData;
