import React, { useEffect } from "react";
import { Accordion, Row, Col } from "react-bootstrap";
import "../../../theme/css/Faq.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function FAQ() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="otherPageBanner faqBanner">
        <div className="text-center title" data-aos="fade-down">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <h6>Get quick answers to your Truckmart queries</h6>
        </div>
      </div>
      <div className="container">
        <div className="faqContain" data-aos="fade-up">
          <Row>
            <Col xs={12} sm={6}>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What services does Truckmart offer?
                  </Accordion.Header>
                  <Accordion.Body>
                    Truckmart specializes in truck repairs, maintenance,
                    spare parts sales, and truck sales. Our goal is to ensure
                    your fleet stays on the road with minimal downtime.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Do you provide roadside assistance?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, Truckmart offers 24/7 roadside assistance for breakdowns.
                    Our team will come to your location to help get your truck back
                    on the road quickly.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Can I purchase used trucks at Truckmart?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, Truckmart offers a selection of high-quality used
                    trucks for sale. All vehicles are inspected and certified
                    to ensure reliability and performance.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col xs={12} sm={6}>
              <Accordion
                defaultActiveKey="1"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Do you stock genuine truck spare parts?
                  </Accordion.Header>
                  <Accordion.Body>
                    Absolutely. We stock a wide range of genuine and OEM spare
                    parts for all major truck brands to keep your vehicle in top
                    condition.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How can I book a repair or maintenance service?
                  </Accordion.Header>
                  <Accordion.Body>
                    You can book a service appointment through our website or
                    by contacting our service center directly. We also accept
                    walk-ins based on availability.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Do you offer fleet management solutions?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we provide comprehensive fleet management services,
                    including preventive maintenance schedules, repair tracking,
                    and cost optimization for your fleet.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
