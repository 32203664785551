import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ServiceGetReady from "../../../assets/Images/Services/ServiceGetReady.png"; // Change image to relevant Truckmart asset
import { Row, Col } from "react-bootstrap";
import "../../../theme/css/Service.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center title">
          <h6>Gear Up with Truckmart</h6>
        </div>
      </div>
      <div className="container">
        <div className="aboutMenu beffect">
          {/* First Row */}
          <Row>
            <Col xl={5} lg={5} md={5} sm={12}>
              <div className="bimg">
                <img
                  className="img-fluid mx-auto"
                  src={ServiceGetReady}
                  alt="Truckmart service"
                  data-aos="flip-left"
                />
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} className="colForResponsive">
              <div className="titleFont">
                <h3>Ashok Leyland Boss Truck</h3>
                <h2>
                  Your Partner in Reliable Truck Services, Sales, and Repairs
                </h2>
              </div>
              
              <button className="ourSurvice">
                <Link to="/faq">Explore Our Services</Link>
              </button>
            </Col>
          </Row>

          {/* Second Row */}
          {/* <Row className="mt-5">
            <Col xl={7} lg={7} md={7} sm={12} className="colForResponsive">
              <div className="titleFont">
                <h3>OUR EXPERTISE</h3>
                <h2>Comprehensive Truck Maintenance and Repairs</h2>
              </div>
              <p>
                With decades of experience, our skilled technicians ensure that
                your trucks receive the highest quality maintenance and repair
                services. We aim to minimize downtime and maximize performance.
              </p>
              <button className="ourSurvice">
                <Link to="/services">Learn More</Link>
              </button>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12}>
              <div className="bimg">
                <img
                  className="img-fluid mx-auto"
                  src={ServiceGetReady}
                  alt="Expertise"
                  data-aos="flip-right"
                />
              </div>
            </Col>
          </Row> */}

          {/* Third Row */}
          {/* <Row className="mt-5">
            <Col xl={5} lg={5} md={5} sm={12}>
              <div className="bimg">
                <img
                  className="img-fluid mx-auto"
                  src={ServiceGetReady}
                  alt="Parts"
                  data-aos="zoom-in"
                />
              </div>
            </Col>
            <Col xl={7} lg={7} md={7} sm={12} className="colForResponsive">
              <div className="titleFont">
                <h3>QUALITY SPARE PARTS</h3>
                <h2>Top-Grade Spare Parts for Every Truck</h2>
              </div>
              <p>
                We provide a wide range of high-quality spare parts to keep your
                trucks running efficiently. All our parts meet the highest
                standards and are sourced from trusted manufacturers.
              </p>
              <button className="ourSurvice">
                <Link to="/parts">View Our Inventory</Link>
              </button>
            </Col>
          </Row> */}

          {/* Fourth Row */}
          {/* <Row className="mt-5">
            <Col xl={7} lg={7} md={7} sm={12} className="colForResponsive">
              <div className="titleFont">
                <h3>OUR COMMITMENT</h3>
                <h2>Exceptional Customer Support</h2>
              </div>
              <p>
                At Truckmart, we prioritize customer satisfaction. Our support
                team is always ready to assist with your queries, ensuring a
                seamless experience for all our clients.
              </p>
              <button className="ourSurvice">
                <Link to="/support">Contact Us</Link>
              </button>
            </Col>
            <Col xl={5} lg={5} md={5} sm={12}>
              <div className="bimg">
                <img
                  className="img-fluid mx-auto"
                  src={ServiceGetReady}
                  alt="Commitment"
                  data-aos="fade-up"
                />
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
}
